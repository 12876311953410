import React from "react"
// import { Table } from "antd"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Footer from "../../components/footer"
import Card from "../../components/card"
// import { vP } from "../../components/gutters"
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale } from "../../utils/typography"
import { itemListAbout } from "../../utils/sidebar/item-list"
// import Introduction from "../../components/about/introduction"
import { graphql } from "gatsby";

// const columns = [
//     {title: '招聘职位', dataIndex: 'position', key: 'position'},
//     {title: '要求', dataIndex: 'requirements', key: 'requirements'},
//     {title: '人数', dataIndex: 'people', key: 'people'},
//     {title: '地点', dataIndex: 'site', key: 'site', filters:[{text: '北京', value: '北京'}, {text: '上海', value: '上海'}, {text: '长沙', value: '长沙'},],
//         filterMultiple: false, onFilter: (value, record) => record.site.indexOf(value) === 0,
//     },
//     {title: '发布时间', dataIndex:'date', key: 'date'},
// ]

const WantPage = (props) => {
  let _content = props.data.markdownRemark
  const { edges: introCards } = props.data.allMarkdownRemark
  const _contentTitle = (
    <>
      <span>
          {_content.frontmatter.title}
      </span>
      <span style={{fontSize: 18, lineHeight: '21px', paddingLeft: 10}}>{_content.frontmatter.subtitle}</span>
    </>
  )
  return (
      <Layout location={props.location} itemList={itemListAbout} contentTitle={_contentTitle}>
          <SEO title="诚聘英才" />
          {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
              <h2 css={styles.contentTitle}>
                  <span>
                      {_content.frontmatter.title}
                  </span>
                  <span style={{fontSize: 18, lineHeight: '21px', paddingLeft: 10}}>{_content.frontmatter.subtitle}</span>
              </h2>
          </div> */}
          <div css={styles.contact} id="joinus">
              <div css={styles.font} dangerouslySetInnerHTML={{ __html: _content.html }}></div>
              {/* <Table
                  dataSource={data}
                  columns={columns}
                  pagination={false}
              /> */}
          </div>
          <div css={styles.contact} style={{paddingTop: 0}} id="introduction">
              <div css={styles.summaryTitle}>
                  <h3 style={{marginBottom: 0}}>{_content.frontmatter.introTitle}</h3>
                  <h4 style={{ paddingLeft: 10, marginBottom: -8 }}>{_content.frontmatter.introSubtitle}</h4>
              </div>
              <div css={styles.cards}>
                  {introCards
                    .map(({ node: card }) => {
                      return (
                        <Card key={card.id}>
                            <div css={styles.font} dangerouslySetInnerHTML={{ __html: card.html }}></div>
                        </Card>
                      )
                    })
                  }
                  {/* <Introduction /> */}
              </div>
          </div>
          {/* <div css={styles.footer}>
            <Footer />
          </div> */}
      </Layout>
  )
}

// const data = [
//     {key: 1, position: '销售经理', requirements: <Link to="/404">查看详细</Link>, people: '3人', site : '北京', date: '2017-02-07'},
//     {key: 2, position: '销售经理', requirements: <Link to="/404">查看详细</Link>, people: '2人', site : '上海', date: '2017-02-07'},
//     {key: 3, position: '销售经理', requirements: <Link to="/404">查看详细</Link>, people: '2人', site : '长沙', date: '2017-02-07'},
// ]

const styles = {
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 100,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    cards: {
        borderRadius: presets.radiusLg,
        display: `flex`,
        flexWrap: `wrap`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        width: `100%`,
    },
    contact: {
        flex: `0 0 100%`,
        maxWidth: `100%`,
        padding: rhythm(presets.gutters.default / 2),
        [presets.Hd]: { padding: 24 },
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    summaryTitle: {
      margin: `0 0 10px 0`,
      padding: '0 15px 15px 15px',
      alignItems: `center`,
      borderBottom: `1px solid ${colors.ui.border}`,
      display: `flex`,
      flexDirection: `row`,
      zIndex: 1,
    },
}

export default WantPage

export const pageQuery = graphql`
  query WantEccQuery {
    allMarkdownRemark(
      sort: {fields: [frontmatter___order], order:ASC }
      filter: { frontmatter: { type: { eq: "about-joinus-card" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { type: { eq: "about-joinus" } }
    ) {
      html
      frontmatter {
        title
        subtitle
        introTitle
        introSubtitle
      }
    }
  }
`
